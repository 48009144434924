import React, { useEffect } from "react";
import { useThree } from "@react-three/fiber";
import { gsap } from "gsap";
// import { CatmullRomCurve3 } from "three";

export default function CameraMotion({ pos }) {
  const { camera } = useThree();
  const dataValue = {
    home: {
      position: [7.5, 1, -1.5],
      rotation: [0, Math.PI / 2 + Math.PI / 10, 0],
    },
    hall: { position: [2.2, 5.6, 3], rotation: [0, -Math.PI / 11, 0] },
    office: { position: [9, 5.3, 1], rotation: [0, 0, 0] },
    exit: {
      position: [23, 5.2, -8.5],
      rotation: [0, Math.PI / 2 + Math.PI / 15, 0],
    },
  };
  useEffect(() => {
    // const curve = new CatmullRomCurve3([
    //   new THREE.Vector3(-2, 0, 2),
    //   new THREE.Vector3(0, 3, -5),
    //   new THREE.Vector3(2, 0, 2),
    // ]);

    const animationDuration = 1; // seconds

    const timeline = gsap.timeline({ repeat: 0, yoyo: false });

    // timeline.to({}, 0, {}); // Needed to start the timeline
    timeline.to(
      camera.rotation,
      {
        duration: animationDuration,
        x: dataValue[pos].rotation[0], // Rotate the camera on the x-axis by 45 degrees
        y: dataValue[pos].rotation[1], // Rotate the camera on the y-axis by 45 degrees
        z: dataValue[pos].rotation[2],
        // ease: "power1.inOut",
      },
      0
    );
    timeline.to(
      camera.position,
      {
        duration: animationDuration,
        x: dataValue[pos].position[0],
        y: dataValue[pos].position[1],
        z: dataValue[pos].position[2],
        // ease: "power1.inOut",
      },
      0
    );

    return () => timeline.kill();

    // timeline.to(
    //   camera.position,
    //   {
    //     duration: animationDuration,
    //     x: curve.getPointAt(0).x,
    //     y: curve.getPointAt(0).y,
    //     z: curve.getPointAt(0).z,
    //   },
    //   animationDuration
    // );

    // timeline.to(
    //   camera.rotation,
    //   {
    //     duration: animationDuration,
    //     x: 0, // Reset the rotation to its initial state
    //     y: 0, // Reset the rotation to its initial state
    //     z: 0,
    //     ease: "power1.inOut",
    //   },
    //   animationDuration
    // );

    // return () => timeline.kill();
  }, [pos]);

  return <></>;
}
