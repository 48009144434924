import React, { Suspense, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { Loader, Environment } from "@react-three/drei";
// import { Model6 } from "./Model5";
import { Model, Instances } from "./Model1";
import CameraMotion from "./CameraMotion";
import {
  LocationOn,
  Cameraswitch,
  ViewInAr,
  TravelExplore,
} from "@mui/icons-material";

// import "./style.css";
import "./App.css";
import {
  BottomNavigation,
  BottomNavigationAction,
  Card,
  CardContent,
  Typography,
} from "@mui/material";

export default function App() {
  const [pos, setPos] = useState("home");
  const dataValue = {
    home: {
      position: [7.5, 1, -1.5],
      rotation: [0, Math.PI / 2 + Math.PI / 10, 0],
    },
    hall: { position: [2.2, 5.6, 3], rotation: [0, 0, 0] },
    office: { position: [9, 5.3, 1], rotation: [0, 0, 0] },
    exit: { position: [25, 5.2, -8.5], rotation: [0, Math.PI / 2, 0] },
  };
  const [value, setValue] = useState("home");

  return (
    <>
      <div className="bottombar">
        <div className="cardhs">
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {pos === "home" &&
                  "Hey there ! Welcome to my meta space. I use this space to post stuff I do in the metaverse."}
                {pos === "hall" &&
                  "I have always been fascinated by the emerging field of metaverse & spatial computing and the amazing possibilities it unlocks."}
                {pos === "office" && "Meet my Team!"}
                {pos === "exit" && (
                  <>
                    Let's stay in touch. Connect with me on
                    <br />
                    <a
                      href="https://www.linkedin.com/in/arshath-mohammed/"
                      style={{ color: "blue" }}
                    >
                      LinkedIn
                      <br />
                    </a>
                  </>
                )}
              </Typography>
            </CardContent>
          </Card>
        </div>
        <br />
        <BottomNavigation
          className="bottomNavigation"
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setPos(newValue);
            setValue(newValue);
          }}
        >
          <BottomNavigationAction
            label="Welcome"
            value="home"
            icon={<Cameraswitch />}
          />
          <BottomNavigationAction
            label="Hall"
            value="hall"
            icon={<ViewInAr className="icon" />}
          />
          <BottomNavigationAction
            label="Office"
            value="office"
            icon={<LocationOn className="icon" />}
          />
          <BottomNavigationAction
            label="Exit"
            value="exit"
            icon={<TravelExplore className="icon" />}
          />
        </BottomNavigation>
      </div>

      <Canvas
        shadows
        dpr={[1, 2]}
        camera={{
          fov: 35,
        }}
      >
        <Suspense fallback={null}>
          <fog attach="fog" args={["#272730", 16, 30]} />
          <CameraMotion pos={pos} />
          <ambientLight intensity={0.5} />
          <Environment files="/venice_sunset_1k.hdr" />

          <Instances>
            <Model pos={pos} />
          </Instances>
        </Suspense>
      </Canvas>
      <Loader />
    </>
  );
}
