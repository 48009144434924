/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {
  useRef,
  useMemo,
  useContext,
  createContext,
  useEffect,
} from "react";
import { useGLTF, Merged, useAnimations } from "@react-three/drei";

const context = createContext();
export function Instances({ children, ...props }) {
  const { nodes } = useGLTF("/office1.glb");
  const instances = useMemo(
    () => ({
      Cube9: nodes.Cube005_1,
      Cube10: nodes.Cube005_2,
      Cylinder: nodes.Cylinder005,
      Cylinder1: nodes.Cylinder005_1,
      Dacfbdcffaafdfecf:
        nodes[
          "639875d7ac140f15311bdc5f_6257f2528a399536038afd2f_5e66223c415f1"
        ],
    }),
    [nodes]
  );
  return (
    <Merged meshes={instances} {...props}>
      {(instances) => (
        <context.Provider value={instances} children={children} />
      )}
    </Merged>
  );
}

export function Model(props) {
  console.log(props);
  const instances = useContext(context);
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/office1.glb");
  const { actions } = useAnimations(animations, group);
  console.log(actions);
  useEffect(() => {
    console.log(props.pos);
    actions["arshath_first"].play();
    actions["arshath_second"].play();
    actions["kp _vr"].play();
    actions["krishna_type"].play();
    actions["sisira_talking"].play();
    actions["surya_listen"].play();
    actions["last_room_pose"].play();
    // if (props.pos === "home")
    // else if (props.pos === "hall")
    // else if (props.pos === "office") {

    // } else if (props.pos === "exit")
  }, []);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Sketchfab_model" rotation={[-Math.PI / 2, 0, 0]}>
          <group
            name="9a89ec8726164b96a25ba58a30240f8cfbx"
            rotation={[Math.PI / 2, 0, 0]}
            scale={0.01}
          >
            <group name="RootNode">
              <group
                name="2nd_floor"
                position={[230.759, 405.977, -470.328]}
                rotation={[-Math.PI / 2, 0, 0]}
                scale={[290.07, 367.828, 9.152]}
              >
                <mesh
                  name="2nd_floor_���������10001_0"
                  castShadow
                  receiveShadow
                  geometry={nodes["2nd_floor_���������10001_0"].geometry}
                  material={materials["10.001"]}
                />
              </group>
              <group
                name="2nd_floor_lighting"
                position={[230.759, 405.977, -470.328]}
                rotation={[-Math.PI / 2, 0, 0]}
                scale={[290.07, 367.828, 9.152]}
              >
                <mesh
                  name="2nd_floor_lighting_Material006_0"
                  castShadow
                  receiveShadow
                  geometry={nodes["2nd_floor_lighting_Material006_0"].geometry}
                  material={materials["Material.006"]}
                />
              </group>
              <group
                name="bottle1"
                position={[28.423, 81.05, 181.481]}
                rotation={[-Math.PI / 2, 0, 0]}
                scale={10.659}
              >
                <mesh
                  name="bottle1_Material007_0"
                  castShadow
                  receiveShadow
                  geometry={nodes.bottle1_Material007_0.geometry}
                  material={materials["Material.007"]}
                />
              </group>
              <group
                name="bottle2"
                position={[32.399, 74.807, 164.086]}
                rotation={[-Math.PI / 2, 0, 0]}
                scale={4.664}
              >
                <mesh
                  name="bottle2_Material008_0"
                  castShadow
                  receiveShadow
                  geometry={nodes.bottle2_Material008_0.geometry}
                  material={materials["Material.008"]}
                />
              </group>
              <group
                name="carpet"
                position={[-16.963, 3.795, 55.583]}
                rotation={[-Math.PI / 2, 0, -Math.PI]}
                scale={[-223.055, -223.055, -3.386]}
              >
                <mesh
                  name="carpet_Fabric_Upholstery_001a_0"
                  castShadow
                  receiveShadow
                  geometry={nodes.carpet_Fabric_Upholstery_001a_0.geometry}
                  material={materials.Fabric_Upholstery_001a}
                />
              </group>
              <group
                name="floor"
                position={[115.136, -3.496, -107.309]}
                rotation={[-Math.PI / 2, 0, 0]}
                scale={[409.821, 566.195, 409.821]}
              >
                <mesh
                  name="floor_���������10002_0"
                  castShadow
                  receiveShadow
                  geometry={nodes["floor_���������10002_0"].geometry}
                  material={materials["10.002"]}
                />
              </group>
              <group
                name="House"
                position={[115.136, 409.631, -276.927]}
                rotation={[-Math.PI / 2, 0, 0]}
                scale={[409.821, 566.195, 409.821]}
              >
                <mesh
                  name="House_���������10_0"
                  castShadow
                  receiveShadow
                  geometry={nodes["House_���������10_0"].geometry}
                  material={materials.material}
                />
              </group>
              <group
                name="lighting"
                position={[0, 229.866, 10.875]}
                rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
                scale={[33.778, 20.831, 33.778]}
              >
                <mesh
                  name="lighting_Material004_0"
                  castShadow
                  receiveShadow
                  geometry={nodes.lighting_Material004_0.geometry}
                  material={materials["Material.004"]}
                />
              </group>
              <group
                name="outside"
                position={[-654.395, 99.503, -442.7]}
                rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
                scale={[478.365, 292.061, 478.365]}
              >
                <mesh
                  name="outside_Material009_0"
                  castShadow
                  receiveShadow
                  geometry={nodes.outside_Material009_0.geometry}
                  material={materials["Material.009"]}
                  position={[0, 0, -0.272]}
                />
                <mesh
                  name="outside_Material009_0001"
                  castShadow
                  receiveShadow
                  geometry={nodes.outside_Material009_0001.geometry}
                  material={materials["Material.009"]}
                  position={[0.612, 2.424, -4.456]}
                  rotation={[-0.579, 0, 0]}
                />
              </group>
              <group
                name="painting"
                position={[-95.418, 138.411, -478.869]}
                rotation={[-1.719, 0, 0]}
                scale={[70.789, 3.492, 138.028]}
              >
                <mesh
                  name="painting_Material010_0"
                  castShadow
                  receiveShadow
                  geometry={nodes.painting_Material010_0.geometry}
                  material={materials["Material.010"]}
                />
              </group>
              <group
                name="piping"
                position={[-75.158, 62.067, -2.552]}
                rotation={[-Math.PI, -Math.PI / 6, -Math.PI / 2]}
                scale={[28.379, 52.06, 18.743]}
              >
                <mesh
                  name="piping_Soft_Black_Fabric001_0"
                  castShadow
                  receiveShadow
                  geometry={nodes.piping_Soft_Black_Fabric001_0.geometry}
                  material={materials["Soft_Black_Fabric.001"]}
                  position={[0.227, 0, 0]}
                  scale={[1.135, 1, 1]}
                />
              </group>
              <group
                name="sofa"
                position={[-84.424, 76.72, 90.033]}
                rotation={[-Math.PI, Math.PI / 3, Math.PI / 2]}
                scale={[39.955, 73.885, 24.623]}
              >
                <mesh
                  name="sofa_Soft_Black_Fabric_0"
                  castShadow
                  receiveShadow
                  geometry={nodes.sofa_Soft_Black_Fabric_0.geometry}
                  material={materials.Soft_Black_Fabric}
                  position={[-0.211, 0, 0]}
                  scale={[1.135, 1, 1]}
                />
              </group>
              <group
                name="Sphere"
                position={[0, 306.181, 74.714]}
                rotation={[-Math.PI / 2, 0, 0]}
                scale={-7.827}
              >
                <mesh
                  name="Sphere_Material002_0"
                  castShadow
                  receiveShadow
                  geometry={nodes.Sphere_Material002_0.geometry}
                  material={materials["Material.002"]}
                />
              </group>
              <group
                name="Sphere001"
                position={[0, 217.816, 10.875]}
                rotation={[-Math.PI / 2, 0, 0]}
                scale={-7.827}
              >
                <mesh
                  name="Sphere001_Material002_0"
                  castShadow
                  receiveShadow
                  geometry={nodes.Sphere001_Material002_0.geometry}
                  material={materials["Material.002"]}
                />
              </group>
              <group
                name="Stairs"
                rotation={[-Math.PI / 2, 0, 0]}
                scale={[100, 100, 110.277]}
              >
                <mesh
                  name="Stairs_���������10003_0"
                  castShadow
                  receiveShadow
                  geometry={nodes["Stairs_���������10003_0"].geometry}
                  material={materials["10.003"]}
                />
              </group>
              <group
                name="table"
                position={[22.445, 36.219, 177.316]}
                rotation={[0, -1.504, 1.571]}
                scale={[28.774, 20.806, 33.738]}
              >
                <mesh
                  name="table_Material011_0"
                  castShadow
                  receiveShadow
                  geometry={nodes.table_Material011_0.geometry}
                  material={materials["Material.011"]}
                />
              </group>
              <group
                name="Window"
                position={[115.136, 409.631, -169.044]}
                rotation={[-Math.PI / 2, 0, 0]}
                scale={[409.821, 566.195, 409.821]}
              >
                <mesh
                  name="Window_Material001_0"
                  castShadow
                  receiveShadow
                  geometry={nodes.Window_Material001_0.geometry}
                  material={materials["Material.001"]}
                />
              </group>
            </group>
          </group>
        </group>
        <group
          name="Sketchfab_model002"
          position={[8.666, 5.093, -6.161]}
          rotation={[-Math.PI / 2, 0, Math.PI]}
          scale={0.175}
        >
          <group
            name="Modern_Dining_Roomfbx"
            rotation={[Math.PI / 2, 0, 0]}
            scale={0.01}
          >
            <group name="RootNode002">
              <group
                name="Modern_Dining_Room"
                position={[0, -4.892, 0]}
                rotation={[-Math.PI / 2, 0, -Math.PI]}
                scale={[1379.1, 1734, 765]}
              >
                <group
                  name="Backdrop001"
                  position={[0, 1.557, 0.006]}
                  rotation={[Math.PI / 2, 0, 0]}
                  scale={[1.943, 3.503, 1.546]}
                >
                  <mesh
                    name="Backdrop_Backdrop_0001"
                    castShadow
                    receiveShadow
                    geometry={nodes.Backdrop_Backdrop_0001.geometry}
                    material={materials["Backdrop.001"]}
                  />
                </group>
                <group
                  name="Carpet"
                  position={[0, 0.058, -1.089]}
                  scale={[0.887, 0.518, 0.173]}
                >
                  <mesh
                    name="Carpet_Carpet_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Carpet_Carpet_0.geometry}
                    material={materials["Carpet.001"]}
                  />
                </group>
                <group
                  name="CurtainPole"
                  position={[0, 1.096, 0.791]}
                  rotation={[0, 1.571, 0]}
                  scale={[0.024, 0.01, 0.615]}
                >
                  <mesh
                    name="CurtainPole_CurtainPole_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.CurtainPole_CurtainPole_0.geometry}
                    material={materials.CurtainPole}
                  />
                </group>
                <group
                  name="Curtains"
                  position={[0, 1.066, 0.27]}
                  scale={[0.16, 0.369, 0.131]}
                >
                  <mesh
                    name="Curtains_Curtains_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Curtains_Curtains_0.geometry}
                    material={materials.Curtains}
                  />
                </group>
                <group
                  name="DiningTable"
                  position={[0, 0.058, -0.567]}
                  scale={[0.145, 0.115, 0.261]}
                >
                  <mesh
                    name="DiningTable_DiningTable_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.DiningTable_DiningTable_0.geometry}
                    material={materials.DiningTable}
                  />
                </group>
                <group
                  name="DoorFrame"
                  position={[0, 1.129, -0.315]}
                  scale={[0.986, 1, 1]}
                >
                  <mesh
                    name="DoorFrame_DoorFrame_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.DoorFrame_DoorFrame_0.geometry}
                    material={materials.DoorFrame}
                  />
                </group>
                <group
                  name="LightChrome"
                  position={[0, 0.058, 0.834]}
                  scale={[0.003, 0.003, 0.006]}
                >
                  <mesh
                    name="LightChrome_LightChrome_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.LightChrome_LightChrome_0.geometry}
                    material={materials.LightChrome}
                  />
                </group>
                <group
                  name="LightGlass"
                  position={[0, 0.058, 0.456]}
                  scale={[0.003, 0.003, 0.006]}
                >
                  <mesh
                    name="LightGlass_Lights_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.LightGlass_Lights_0.geometry}
                    material={materials.Lights}
                  />
                </group>
                <mesh
                  name="Modern_Dining_Room_Floor_0"
                  castShadow
                  receiveShadow
                  geometry={nodes.Modern_Dining_Room_Floor_0.geometry}
                  material={materials["Floor.001"]}
                />
                <group
                  name="Paintings"
                  position={[1.174, 0.173, 0.006]}
                  scale={[0.073, 0.058, 0.131]}
                >
                  <mesh
                    name="Paintings_Paintings_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Paintings_Paintings_0.geometry}
                    material={materials.Paintings}
                  />
                </group>
                <group
                  name="Picture"
                  position={[-1.173, 0.058, 0.006]}
                  scale={[0.516, 0.615, 0.929]}
                >
                  <mesh
                    name="Picture_Picture_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Picture_Picture_0.geometry}
                    material={materials.Picture}
                  />
                </group>
                <group
                  name="PictureFrame"
                  position={[-1.177, 0.058, 0.006]}
                  scale={[0.516, 0.615, 0.929]}
                >
                  <mesh
                    name="PictureFrame_PictureFrame_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.PictureFrame_PictureFrame_0.geometry}
                    material={materials.PictureFrame}
                  />
                </group>
                <group
                  name="Plant"
                  position={[0.001, 0.058, -0.177]}
                  scale={[0.044, 0.035, 0.078]}
                />
                <group
                  name="Pot"
                  position={[0, 0.058, -0.429]}
                  scale={[0.073, 0.058, 0.131]}
                />
                <group
                  name="Roof"
                  position={[0, 0, 1.106]}
                  scale={[0.986, 1, 1]}
                >
                  <mesh
                    name="Roof_Roof_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Roof_Roof_0.geometry}
                    material={materials.Roof}
                  />
                </group>
                <group
                  name="Sun"
                  position={[0, 0, 0.006]}
                  rotation={[0.567, 0, 0]}
                  scale={[0.073, 0.082, 0.117]}
                >
                  <group name="Object_12" rotation={[Math.PI / 2, 0, 0]}>
                    <group name="Object_13" />
                  </group>
                </group>
                <group
                  name="Walls"
                  position={[0, 0, 0.006]}
                  scale={[0.986, 1, 1]}
                >
                  <mesh
                    name="Walls_Walls_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Walls_Walls_0.geometry}
                    material={materials["Walls.001"]}
                  />
                </group>
                <group
                  name="Window001"
                  position={[0, 0, 0.006]}
                  scale={[0.986, 1, 1]}
                >
                  <mesh
                    name="Window_Window_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Window_Window_0.geometry}
                    material={materials["Window.001"]}
                  />
                </group>
              </group>
            </group>
          </group>
        </group>
        <group
          name="Camera"
          position={[0.811, -2.396, 5.178]}
          rotation={[-2.61, 1.518, -2.174]}
          scale={[0.02, 0.045, 0.025]}
        />
        <group name="Object_8" />
        <group
          name="Sketchfab_model003"
          position={[16.696, 2.204, -8.043]}
          rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        >
          <group name="WhiteRoomobjcleanermaterialmergergles">
            <mesh
              name="Object_2"
              castShadow
              receiveShadow
              geometry={nodes.Object_2.geometry}
              material={materials["material.003"]}
            />
            <mesh
              name="Object_3"
              castShadow
              receiveShadow
              geometry={nodes.Object_3.geometry}
              material={materials["material.003"]}
            />
          </group>
        </group>
        <group
          name="Sketchfab_model006"
          position={[1.664, 3.974, -4.202]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={0.015}
        >
          <group
            name="9c2d020f024c46c28daefa4b6c8bf13dfbx"
            rotation={[Math.PI / 2, 0, 0]}
          >
            <group name="RootNode005">
              <group name="Balkar" rotation={[-Math.PI / 2, 0, 0]}>
                <mesh
                  name="Balkar_feher_mubor_0"
                  castShadow
                  receiveShadow
                  geometry={nodes.Balkar_feher_mubor_0.geometry}
                  material={materials.feher_mubor}
                  position={[-95.207, -89.802, 0]}
                  rotation={[0, 0, Math.PI / 2]}
                />
              </group>
              <group
                name="Balkar001"
                position={[72.187, 0, 0]}
                rotation={[-Math.PI / 2, 0, 0]}
              >
                <mesh
                  name="Balkar001_feher_mubor_0"
                  castShadow
                  receiveShadow
                  geometry={nodes.Balkar001_feher_mubor_0.geometry}
                  material={materials.feher_mubor}
                  position={[-167.394, -17.615, 0]}
                  rotation={[0, 0, Math.PI / 2]}
                />
              </group>
              <group name="Hatsoresz" rotation={[-Math.PI / 2, 0, 0]}>
                <mesh
                  name="Hatsoresz_feher_mubor_0"
                  castShadow
                  receiveShadow
                  geometry={nodes.Hatsoresz_feher_mubor_0.geometry}
                  material={materials.feher_mubor}
                  position={[-95.207, -89.802, 0]}
                  rotation={[0, 0, Math.PI / 2]}
                />
              </group>
              <group
                name="Labak01"
                position={[-81.144, 8.936, 38.306]}
                rotation={[-Math.PI / 2, 0, 0]}
              >
                <mesh
                  name="Labak01_Material_#2_0"
                  castShadow
                  receiveShadow
                  geometry={nodes["Labak01_Material_#2_0"].geometry}
                  material={materials.Material_2}
                  position={[24.244, -132.64, 0]}
                  rotation={[0, 0, Math.PI / 2]}
                />
              </group>
              <group
                name="Labak02"
                position={[81.762, 8.936, 38.306]}
                rotation={[-Math.PI / 2, 0, 0]}
              >
                <mesh
                  name="Labak02_Material_#2_0"
                  castShadow
                  receiveShadow
                  geometry={nodes["Labak02_Material_#2_0"].geometry}
                  material={materials.Material_2}
                  position={[-138.663, 30.266, 0]}
                  rotation={[0, 0, Math.PI / 2]}
                />
              </group>
              <group
                name="Labak03"
                position={[-81.144, 8.936, -38.286]}
                rotation={[-Math.PI / 2, 0, 0]}
              >
                <mesh
                  name="Labak03_Material_#2_0"
                  castShadow
                  receiveShadow
                  geometry={nodes["Labak03_Material_#2_0"].geometry}
                  material={materials.Material_2}
                  position={[-52.348, -209.233, 0]}
                  rotation={[0, 0, Math.PI / 2]}
                />
              </group>
              <group
                name="Labak04"
                position={[81.762, 8.936, -38.286]}
                rotation={[-Math.PI / 2, 0, 0]}
              >
                <mesh
                  name="Labak04_Material_#2_0"
                  castShadow
                  receiveShadow
                  geometry={nodes["Labak04_Material_#2_0"].geometry}
                  material={materials.Material_2}
                  position={[-215.255, -46.326, 0]}
                  rotation={[0, 0, Math.PI / 2]}
                />
              </group>
              <group name="Uloresz" rotation={[-Math.PI / 2, 0, 0]}>
                <mesh
                  name="Uloresz_feher_mubor_0"
                  castShadow
                  receiveShadow
                  geometry={nodes.Uloresz_feher_mubor_0.geometry}
                  material={materials.feher_mubor}
                  position={[-95.207, -89.802, 0]}
                  rotation={[0, 0, Math.PI / 2]}
                />
              </group>
            </group>
          </group>
        </group>
        <mesh
          name="CoffeeTable_CoffeeTable_0001"
          castShadow
          receiveShadow
          geometry={nodes.CoffeeTable_CoffeeTable_0001.geometry}
          material={materials.CoffeeTable}
          position={[1.796, 4.26, -2.828]}
          rotation={[-Math.PI / 2, 0, -Math.PI]}
          scale={0.255}
        />
        <mesh
          name="Window_Books_0001"
          castShadow
          receiveShadow
          geometry={nodes.Window_Books_0001.geometry}
          material={materials.Books}
          position={[1.839, 4.402, -3.225]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={2.595}
        />
        <mesh
          name="Window_Plant_0001"
          castShadow
          receiveShadow
          geometry={nodes.Window_Plant_0001.geometry}
          material={materials["Plant.001"]}
          position={[4.683, 4.537, -0.434]}
          rotation={[-Math.PI / 2, 0, -2.017]}
          scale={1.586}
        />
        <group
          name="back"
          position={[5.607, 5.401, -1000.1]}
          rotation={[0, -1.571, 0]}
        >
          <group name="Object_6" position={[27.787, -265.433, -133.153]} />
        </group>
        <group
          name="bottom"
          position={[-4.454, -1000.1, 7.078]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
        >
          <group name="Object_8001" />
        </group>
        <group
          name="Armature"
          position={[10.867, 3.64, -7.178]}
          rotation={[0, -0.446, 0]}
        >
          <skinnedMesh
            name="EyeLeft"
            geometry={nodes.EyeLeft.geometry}
            material={materials.Wolf3D_Eye}
            skeleton={nodes.EyeLeft.skeleton}
            morphTargetDictionary={nodes.EyeLeft.morphTargetDictionary}
            morphTargetInfluences={nodes.EyeLeft.morphTargetInfluences}
          />
          <skinnedMesh
            name="EyeRight"
            geometry={nodes.EyeRight.geometry}
            material={materials.Wolf3D_Eye}
            skeleton={nodes.EyeRight.skeleton}
            morphTargetDictionary={nodes.EyeRight.morphTargetDictionary}
            morphTargetInfluences={nodes.EyeRight.morphTargetInfluences}
          />
          <skinnedMesh
            name="Wolf3D_Body002"
            geometry={nodes.Wolf3D_Body002.geometry}
            material={materials["Wolf3D_Body.004"]}
            skeleton={nodes.Wolf3D_Body002.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Glasses"
            geometry={nodes.Wolf3D_Glasses.geometry}
            material={materials.Wolf3D_Glasses}
            skeleton={nodes.Wolf3D_Glasses.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Hair"
            geometry={nodes.Wolf3D_Hair.geometry}
            material={materials.Wolf3D_Hair}
            skeleton={nodes.Wolf3D_Hair.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Head"
            geometry={nodes.Wolf3D_Head.geometry}
            material={materials.Wolf3D_Skin}
            skeleton={nodes.Wolf3D_Head.skeleton}
            morphTargetDictionary={nodes.Wolf3D_Head.morphTargetDictionary}
            morphTargetInfluences={nodes.Wolf3D_Head.morphTargetInfluences}
          />
          <skinnedMesh
            name="Wolf3D_Outfit_Bottom"
            geometry={nodes.Wolf3D_Outfit_Bottom.geometry}
            material={materials.Wolf3D_Outfit_Bottom}
            skeleton={nodes.Wolf3D_Outfit_Bottom.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Outfit_Footwear"
            geometry={nodes.Wolf3D_Outfit_Footwear.geometry}
            material={materials.Wolf3D_Outfit_Footwear}
            skeleton={nodes.Wolf3D_Outfit_Footwear.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Outfit_Top"
            geometry={nodes.Wolf3D_Outfit_Top.geometry}
            material={materials["Wolf3D_Outfit_Top.008"]}
            skeleton={nodes.Wolf3D_Outfit_Top.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Teeth"
            geometry={nodes.Wolf3D_Teeth.geometry}
            material={materials.Wolf3D_Teeth}
            skeleton={nodes.Wolf3D_Teeth.skeleton}
            morphTargetDictionary={nodes.Wolf3D_Teeth.morphTargetDictionary}
            morphTargetInfluences={nodes.Wolf3D_Teeth.morphTargetInfluences}
          />
          <primitive object={nodes.Hips} />
        </group>
        <group
          name="Armature003"
          position={[6.327, 3.64, -6.056]}
          rotation={[0, 0.803, 0]}
        >
          <skinnedMesh
            name="EyeLeft003"
            geometry={nodes.EyeLeft003.geometry}
            material={materials["Wolf3D_Eye.005"]}
            skeleton={nodes.EyeLeft003.skeleton}
            morphTargetDictionary={nodes.EyeLeft003.morphTargetDictionary}
            morphTargetInfluences={nodes.EyeLeft003.morphTargetInfluences}
          />
          <skinnedMesh
            name="EyeRight003"
            geometry={nodes.EyeRight003.geometry}
            material={materials["Wolf3D_Eye.005"]}
            skeleton={nodes.EyeRight003.skeleton}
            morphTargetDictionary={nodes.EyeRight003.morphTargetDictionary}
            morphTargetInfluences={nodes.EyeRight003.morphTargetInfluences}
          />
          <skinnedMesh
            name="Wolf3D_Body003"
            geometry={nodes.Wolf3D_Body003.geometry}
            material={materials["Wolf3D_Body.005"]}
            skeleton={nodes.Wolf3D_Body003.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Hair003"
            geometry={nodes.Wolf3D_Hair003.geometry}
            material={materials["Wolf3D_Hair.005"]}
            skeleton={nodes.Wolf3D_Hair003.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Head003"
            geometry={nodes.Wolf3D_Head003.geometry}
            material={materials["Wolf3D_Skin.005"]}
            skeleton={nodes.Wolf3D_Head003.skeleton}
            morphTargetDictionary={nodes.Wolf3D_Head003.morphTargetDictionary}
            morphTargetInfluences={nodes.Wolf3D_Head003.morphTargetInfluences}
          />
          <skinnedMesh
            name="Wolf3D_Outfit_Bottom003"
            geometry={nodes.Wolf3D_Outfit_Bottom003.geometry}
            material={materials["Wolf3D_Outfit_Bottom.005"]}
            skeleton={nodes.Wolf3D_Outfit_Bottom003.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Outfit_Footwear003"
            geometry={nodes.Wolf3D_Outfit_Footwear003.geometry}
            material={materials["Wolf3D_Outfit_Footwear.005"]}
            skeleton={nodes.Wolf3D_Outfit_Footwear003.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Outfit_Top004"
            geometry={nodes.Wolf3D_Outfit_Top004.geometry}
            material={materials["Wolf3D_Outfit_Top.009"]}
            skeleton={nodes.Wolf3D_Outfit_Top004.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Teeth003"
            geometry={nodes.Wolf3D_Teeth003.geometry}
            material={materials["Wolf3D_Teeth.005"]}
            skeleton={nodes.Wolf3D_Teeth003.skeleton}
            morphTargetDictionary={nodes.Wolf3D_Teeth003.morphTargetDictionary}
            morphTargetInfluences={nodes.Wolf3D_Teeth003.morphTargetInfluences}
          />
          <primitive object={nodes.Hips_1} />
        </group>
        <group name="Armature004" position={[9.355, 3.216, -7.34]}>
          <skinnedMesh
            name="EyeLeft004"
            geometry={nodes.EyeLeft004.geometry}
            material={materials["Wolf3D_Eye.006"]}
            skeleton={nodes.EyeLeft004.skeleton}
            morphTargetDictionary={nodes.EyeLeft004.morphTargetDictionary}
            morphTargetInfluences={nodes.EyeLeft004.morphTargetInfluences}
          />
          <skinnedMesh
            name="EyeRight004"
            geometry={nodes.EyeRight004.geometry}
            material={materials["Wolf3D_Eye.006"]}
            skeleton={nodes.EyeRight004.skeleton}
            morphTargetDictionary={nodes.EyeRight004.morphTargetDictionary}
            morphTargetInfluences={nodes.EyeRight004.morphTargetInfluences}
          />
          <skinnedMesh
            name="Wolf3D_Body004"
            geometry={nodes.Wolf3D_Body004.geometry}
            material={materials["Wolf3D_Body.006"]}
            skeleton={nodes.Wolf3D_Body004.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Hair004"
            geometry={nodes.Wolf3D_Hair004.geometry}
            material={materials["Wolf3D_Hair.006"]}
            skeleton={nodes.Wolf3D_Hair004.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Head004"
            geometry={nodes.Wolf3D_Head004.geometry}
            material={materials["Wolf3D_Skin.006"]}
            skeleton={nodes.Wolf3D_Head004.skeleton}
            morphTargetDictionary={nodes.Wolf3D_Head004.morphTargetDictionary}
            morphTargetInfluences={nodes.Wolf3D_Head004.morphTargetInfluences}
          />
          <skinnedMesh
            name="Wolf3D_Outfit_Bottom004"
            geometry={nodes.Wolf3D_Outfit_Bottom004.geometry}
            material={materials["Wolf3D_Outfit_Bottom.006"]}
            skeleton={nodes.Wolf3D_Outfit_Bottom004.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Outfit_Footwear004"
            geometry={nodes.Wolf3D_Outfit_Footwear004.geometry}
            material={materials["Wolf3D_Outfit_Footwear.006"]}
            skeleton={nodes.Wolf3D_Outfit_Footwear004.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Outfit_Top005"
            geometry={nodes.Wolf3D_Outfit_Top005.geometry}
            material={materials["Wolf3D_Outfit_Top.010"]}
            skeleton={nodes.Wolf3D_Outfit_Top005.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Teeth004"
            geometry={nodes.Wolf3D_Teeth004.geometry}
            material={materials["Wolf3D_Teeth.006"]}
            skeleton={nodes.Wolf3D_Teeth004.skeleton}
            morphTargetDictionary={nodes.Wolf3D_Teeth004.morphTargetDictionary}
            morphTargetInfluences={nodes.Wolf3D_Teeth004.morphTargetInfluences}
          />
          <primitive object={nodes.Hips_2} />
        </group>
        <mesh
          name="Window_Books_0002"
          castShadow
          receiveShadow
          geometry={nodes.Window_Books_0002.geometry}
          material={materials.Books}
          position={[7.723, 4.402, -6.118]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={2.595}
        />
        <mesh
          name="Window_Books_0003"
          castShadow
          receiveShadow
          geometry={nodes.Window_Books_0003.geometry}
          material={materials.Books}
          position={[9.757, 4.402, -6.805]}
          rotation={[-Math.PI / 2, 0, -1.898]}
          scale={2.595}
        />
        <mesh
          name="Window_Table_Decoration_0001"
          castShadow
          receiveShadow
          geometry={nodes.Window_Table_Decoration_0001.geometry}
          material={materials.Table_Decoration}
          position={[1.826, 4.426, -2.273]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={1.549}
        />
        <mesh
          name="Window_Table_Decoration_0002"
          castShadow
          receiveShadow
          geometry={nodes.Window_Table_Decoration_0002.geometry}
          material={materials.Table_Decoration}
          position={[8.254, 4.401, -6.378]}
          rotation={[-Math.PI / 2, 0, -1.231]}
          scale={1.895}
        />
        <group name="Armature005" position={[7.957, 3.273, -7.423]}>
          <skinnedMesh
            name="EyeLeft005"
            geometry={nodes.EyeLeft005.geometry}
            material={materials["Wolf3D_Eye.007"]}
            skeleton={nodes.EyeLeft005.skeleton}
            morphTargetDictionary={nodes.EyeLeft005.morphTargetDictionary}
            morphTargetInfluences={nodes.EyeLeft005.morphTargetInfluences}
          />
          <skinnedMesh
            name="EyeRight005"
            geometry={nodes.EyeRight005.geometry}
            material={materials["Wolf3D_Eye.007"]}
            skeleton={nodes.EyeRight005.skeleton}
            morphTargetDictionary={nodes.EyeRight005.morphTargetDictionary}
            morphTargetInfluences={nodes.EyeRight005.morphTargetInfluences}
          />
          <skinnedMesh
            name="Wolf3D_Beard"
            geometry={nodes.Wolf3D_Beard.geometry}
            material={materials.Wolf3D_Beard}
            skeleton={nodes.Wolf3D_Beard.skeleton}
            morphTargetDictionary={nodes.Wolf3D_Beard.morphTargetDictionary}
            morphTargetInfluences={nodes.Wolf3D_Beard.morphTargetInfluences}
          />
          <skinnedMesh
            name="Wolf3D_Body005"
            geometry={nodes.Wolf3D_Body005.geometry}
            material={materials["Wolf3D_Body.007"]}
            skeleton={nodes.Wolf3D_Body005.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Glasses001"
            geometry={nodes.Wolf3D_Glasses001.geometry}
            material={materials["Wolf3D_Glasses.001"]}
            skeleton={nodes.Wolf3D_Glasses001.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Hair005"
            geometry={nodes.Wolf3D_Hair005.geometry}
            material={materials["Wolf3D_Hair.007"]}
            skeleton={nodes.Wolf3D_Hair005.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Head005"
            geometry={nodes.Wolf3D_Head005.geometry}
            material={materials["Wolf3D_Skin.007"]}
            skeleton={nodes.Wolf3D_Head005.skeleton}
            morphTargetDictionary={nodes.Wolf3D_Head005.morphTargetDictionary}
            morphTargetInfluences={nodes.Wolf3D_Head005.morphTargetInfluences}
          />
          <skinnedMesh
            name="Wolf3D_Outfit_Bottom005"
            geometry={nodes.Wolf3D_Outfit_Bottom005.geometry}
            material={materials["Wolf3D_Outfit_Bottom.007"]}
            skeleton={nodes.Wolf3D_Outfit_Bottom005.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Outfit_Footwear005"
            geometry={nodes.Wolf3D_Outfit_Footwear005.geometry}
            material={materials["Wolf3D_Outfit_Footwear.007"]}
            skeleton={nodes.Wolf3D_Outfit_Footwear005.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Outfit_Top006"
            geometry={nodes.Wolf3D_Outfit_Top006.geometry}
            material={materials["Wolf3D_Outfit_Top.011"]}
            skeleton={nodes.Wolf3D_Outfit_Top006.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Teeth005"
            geometry={nodes.Wolf3D_Teeth005.geometry}
            material={materials["Wolf3D_Teeth.007"]}
            skeleton={nodes.Wolf3D_Teeth005.skeleton}
            morphTargetDictionary={nodes.Wolf3D_Teeth005.morphTargetDictionary}
            morphTargetInfluences={nodes.Wolf3D_Teeth005.morphTargetInfluences}
          />
          <primitive object={nodes.Hips_3} />
        </group>
        <mesh
          name="PictureFrame_Material_0001"
          castShadow
          receiveShadow
          geometry={nodes.PictureFrame_Material_0001.geometry}
          material={materials["Material.005"]}
          position={[2.561, 2.01, 3.062]}
          rotation={[-Math.PI / 2, 0, Math.PI / 2]}
          scale={[0.193, 0.55, 0.55]}
        />
        <group
          name="Armature001"
          position={[1.154, 0.392, -0.278]}
          rotation={[-1.692, 0.195, 2.13]}
          scale={1.161}
        >
          <skinnedMesh
            name="EyeLeft001"
            geometry={nodes.EyeLeft001.geometry}
            material={materials["Wolf3D_Eye.001"]}
            skeleton={nodes.EyeLeft001.skeleton}
            morphTargetDictionary={nodes.EyeLeft001.morphTargetDictionary}
            morphTargetInfluences={nodes.EyeLeft001.morphTargetInfluences}
          />
          <skinnedMesh
            name="EyeRight001"
            geometry={nodes.EyeRight001.geometry}
            material={materials["Wolf3D_Eye.001"]}
            skeleton={nodes.EyeRight001.skeleton}
            morphTargetDictionary={nodes.EyeRight001.morphTargetDictionary}
            morphTargetInfluences={nodes.EyeRight001.morphTargetInfluences}
          />
          <skinnedMesh
            name="Wolf3D_Body"
            geometry={nodes.Wolf3D_Body.geometry}
            material={materials.Wolf3D_Body}
            skeleton={nodes.Wolf3D_Body.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Hair001"
            geometry={nodes.Wolf3D_Hair001.geometry}
            material={materials["Wolf3D_Hair.001"]}
            skeleton={nodes.Wolf3D_Hair001.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Head001"
            geometry={nodes.Wolf3D_Head001.geometry}
            material={materials["Wolf3D_Skin.001"]}
            skeleton={nodes.Wolf3D_Head001.skeleton}
            morphTargetDictionary={nodes.Wolf3D_Head001.morphTargetDictionary}
            morphTargetInfluences={nodes.Wolf3D_Head001.morphTargetInfluences}
          />
          <skinnedMesh
            name="Wolf3D_Outfit_Bottom001"
            geometry={nodes.Wolf3D_Outfit_Bottom001.geometry}
            material={materials["Wolf3D_Outfit_Bottom.001"]}
            skeleton={nodes.Wolf3D_Outfit_Bottom001.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Outfit_Footwear001"
            geometry={nodes.Wolf3D_Outfit_Footwear001.geometry}
            material={materials["Wolf3D_Outfit_Footwear.001"]}
            skeleton={nodes.Wolf3D_Outfit_Footwear001.skeleton}
          />
          <group name="Wolf3D_Outfit_Top001">
            <skinnedMesh
              name="Wolf3D_Outfit_Top001_1"
              geometry={nodes.Wolf3D_Outfit_Top001_1.geometry}
              material={materials["Wolf3D_Outfit_Top.001"]}
              skeleton={nodes.Wolf3D_Outfit_Top001_1.skeleton}
            />
            <skinnedMesh
              name="Wolf3D_Outfit_Top001_2"
              geometry={nodes.Wolf3D_Outfit_Top001_2.geometry}
              material={materials.Wolf3D_Outfit_Top}
              skeleton={nodes.Wolf3D_Outfit_Top001_2.skeleton}
            />
          </group>
          <skinnedMesh
            name="Wolf3D_Teeth001"
            geometry={nodes.Wolf3D_Teeth001.geometry}
            material={materials["Wolf3D_Teeth.001"]}
            skeleton={nodes.Wolf3D_Teeth001.skeleton}
            morphTargetDictionary={nodes.Wolf3D_Teeth001.morphTargetDictionary}
            morphTargetInfluences={nodes.Wolf3D_Teeth001.morphTargetInfluences}
          />
          <primitive object={nodes.Hips_4} />
        </group>
        <group
          name="Cube"
          position={[15.284, 7.124, -4.697]}
          rotation={[-Math.PI, 0, 0]}
          scale={[-0.565, -0.758, -0.043]}
        >
          <mesh
            name="Cube001_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube001_1.geometry}
            material={materials.white}
          />
          <mesh
            name="Cube001_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube001_2.geometry}
            material={materials["Material.033"]}
          />
          <mesh
            name="Cube001_3"
            castShadow
            receiveShadow
            geometry={nodes.Cube001_3.geometry}
            material={materials["linkedin-icon-2048x2048-ya5g47j2"]}
          />
        </group>
        <group
          name="Cube001"
          position={[13.804, 7.124, -4.697]}
          rotation={[-Math.PI, 0, 0]}
          scale={[-0.55, -0.738, -0.042]}
        >
          <mesh
            name="Cube002_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube002_1.geometry}
            material={materials.white}
          />
          <mesh
            name="Cube002_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube002_2.geometry}
            material={materials["Material.035"]}
          />
          <mesh
            name="Cube002_3"
            castShadow
            receiveShadow
            geometry={nodes.Cube002_3.geometry}
            material={materials["WhatsApp Image 2023-07-30 at 14.06.06"]}
          />
        </group>
        <mesh
          name="Wolf3D_Outfit_Top002"
          castShadow
          receiveShadow
          geometry={nodes.Wolf3D_Outfit_Top002.geometry}
          material={materials["Wolf3D_Outfit_Top.006"]}
          position={[3.796, 5.511, -4.256]}
          rotation={[0, -0.404, 0]}
          scale={[1.207, 1.104, 1.104]}
        />
        <group
          name="Armature002"
          position={[3.824, 4.012, -4.336]}
          rotation={[0, -0.404, 0]}
          scale={1.104}
        >
          <skinnedMesh
            name="EyeLeft002"
            geometry={nodes.EyeLeft002.geometry}
            material={materials["Wolf3D_Eye.004"]}
            skeleton={nodes.EyeLeft002.skeleton}
            morphTargetDictionary={nodes.EyeLeft002.morphTargetDictionary}
            morphTargetInfluences={nodes.EyeLeft002.morphTargetInfluences}
          />
          <skinnedMesh
            name="EyeRight002"
            geometry={nodes.EyeRight002.geometry}
            material={materials["Wolf3D_Eye.004"]}
            skeleton={nodes.EyeRight002.skeleton}
            morphTargetDictionary={nodes.EyeRight002.morphTargetDictionary}
            morphTargetInfluences={nodes.EyeRight002.morphTargetInfluences}
          />
          <skinnedMesh
            name="Wolf3D_Body001"
            geometry={nodes.Wolf3D_Body001.geometry}
            material={materials["Wolf3D_Body.003"]}
            skeleton={nodes.Wolf3D_Body001.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Hair002"
            geometry={nodes.Wolf3D_Hair002.geometry}
            material={materials["Wolf3D_Hair.004"]}
            skeleton={nodes.Wolf3D_Hair002.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Head002"
            geometry={nodes.Wolf3D_Head002.geometry}
            material={materials["Wolf3D_Skin.004"]}
            skeleton={nodes.Wolf3D_Head002.skeleton}
            morphTargetDictionary={nodes.Wolf3D_Head002.morphTargetDictionary}
            morphTargetInfluences={nodes.Wolf3D_Head002.morphTargetInfluences}
          />
          <skinnedMesh
            name="Wolf3D_Outfit_Bottom002"
            geometry={nodes.Wolf3D_Outfit_Bottom002.geometry}
            material={materials["Wolf3D_Outfit_Bottom.004"]}
            skeleton={nodes.Wolf3D_Outfit_Bottom002.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Outfit_Footwear002"
            geometry={nodes.Wolf3D_Outfit_Footwear002.geometry}
            material={materials["Wolf3D_Outfit_Footwear.004"]}
            skeleton={nodes.Wolf3D_Outfit_Footwear002.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Outfit_Top003"
            geometry={nodes.Wolf3D_Outfit_Top003.geometry}
            material={materials["Wolf3D_Outfit_Top.007"]}
            skeleton={nodes.Wolf3D_Outfit_Top003.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Teeth002"
            geometry={nodes.Wolf3D_Teeth002.geometry}
            material={materials["Wolf3D_Teeth.004"]}
            skeleton={nodes.Wolf3D_Teeth002.skeleton}
            morphTargetDictionary={nodes.Wolf3D_Teeth002.morphTargetDictionary}
            morphTargetInfluences={nodes.Wolf3D_Teeth002.morphTargetInfluences}
          />
          <primitive object={nodes.Hips_5} />
        </group>
        <mesh
          name="Cube002"
          castShadow
          receiveShadow
          geometry={nodes.Cube002.geometry}
          material={materials.white}
          position={[5.25, 5.167, -3.388]}
          scale={[0.032, 1.187, 0.666]}
        />
        <group
          name="Cube003"
          position={[5.214, 5.299, -2.774]}
          rotation={[0, -0.272, 0]}
          scale={[0.032, 1.187, 0.666]}
        >
          <mesh
            name="Cube004_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube004_1.geometry}
            material={materials.white}
          />
          <mesh
            name="Cube004_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube004_2.geometry}
            material={materials["Material.032"]}
          />
        </group>
        <group
          name="Cube004"
          position={[9.452, 4.425, -6.014]}
          rotation={[Math.PI, 1.244, 2.395]}
          scale={[0.775, 1.307, 1.163]}
        >
          <instances.Cube9 name="Cube005_1" />
          <instances.Cube10 name="Cube005_2" />
        </group>
        <group
          name="Cube005"
          position={[7.94, 4.425, -6.717]}
          rotation={[0.747, -Math.PI / 2, 0]}
          scale={[0.775, 1.307, 1.163]}
        >
          <instances.Cube9 name="Cube005_1" />
          <instances.Cube10 name="Cube005_2" />
        </group>
        <mesh
          name="Cube006"
          castShadow
          receiveShadow
          geometry={nodes.Cube006.geometry}
          material={materials["Material.040"]}
          position={[6.444, 5.237, -5.943]}
          rotation={[0, 1.159, 0]}
        />
        <group
          name="Cylinder040"
          position={[8.245, 4.401, -5.933]}
          rotation={[0, -1.145, 0]}
          scale={0.047}
        >
          <instances.Cylinder name="Cylinder005" />
          <instances.Cylinder1 name="Cylinder005_1" />
        </group>
        <group
          name="Cylinder001"
          position={[9.507, 4.401, -6.678]}
          rotation={[0, 0.383, 0]}
          scale={0.047}
        >
          <instances.Cylinder name="Cylinder005" />
          <instances.Cylinder1 name="Cylinder005_1" />
        </group>
        <group
          name="Cylinder002"
          position={[7.551, 4.401, -6.641]}
          rotation={[-Math.PI, 1.056, -Math.PI]}
          scale={0.047}
        >
          <instances.Cylinder name="Cylinder005" />
          <instances.Cylinder1 name="Cylinder005_1" />
        </group>
        <instances.Dacfbdcffaafdfecf
          name="639875d7ac140f15311bdc5f_6257f2528a399536038afd2f_5e66223c415f1"
          position={[8.667, 4.358, -6.327]}
          rotation={[0, -0.339, 0]}
          scale={0.399}
        />
        <instances.Dacfbdcffaafdfecf
          name="639875d7ac140f15311bdc5f_6257f2528a399536038afd2f_5e66223c4"
          position={[8.738, 4.358, -6.116]}
          rotation={[0, 0.283, 0]}
          scale={0.399}
        />
        <mesh
          name="Plane"
          castShadow
          receiveShadow
          geometry={nodes.Plane.geometry}
          material={materials["Material.042"]}
          position={[7.936, 4.357, -6.929]}
          scale={[0.139, 0.071, 0.071]}
        />
        <mesh
          name="Cube010"
          castShadow
          receiveShadow
          geometry={nodes.Cube010.geometry}
          material={materials["Material.043"]}
          position={[7.94, 4.426, -6.717]}
          rotation={[0.747, -Math.PI / 2, 0]}
          scale={[0.775, 1.307, 1.163]}
        />
        <group
          name="Cube012"
          position={[0.689, 7.311, -5.037]}
          scale={[0.71, 0.462, 0.027]}
        >
          <mesh
            name="Cube_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube_1.geometry}
            material={materials["Material.037"]}
          />
          <mesh
            name="Cube_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube_2.geometry}
            material={materials.Material}
          />
        </group>
        <group
          name="Cube013"
          position={[2.332, 6.572, -5.037]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={[1.201, 0.781, 0.034]}
        >
          <mesh
            name="Cube010_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube010_1.geometry}
            material={materials["Material.037"]}
          />
          <mesh
            name="Cube010_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube010_2.geometry}
            material={materials["Material.003"]}
          />
        </group>
        <group
          name="Cube014"
          position={[3.963, 5.833, -5.037]}
          rotation={[0, 0, -Math.PI]}
          scale={[0.71, 0.462, 0.027]}
        >
          <mesh
            name="Cube011"
            castShadow
            receiveShadow
            geometry={nodes.Cube011.geometry}
            material={materials["Material.037"]}
          />
          <mesh
            name="Cube011_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube011_1.geometry}
            material={materials["Material.012"]}
          />
        </group>
        <mesh
          name="WhatsApp_Image_2023-08-04_at_170159"
          castShadow
          receiveShadow
          geometry={nodes["WhatsApp_Image_2023-08-04_at_170159"].geometry}
          material={materials["WhatsApp Image 2023-08-04 at 17.01.59"]}
          position={[5.221, 6.128, -1.213]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={1.537}
        />
        <group
          name="Armature007"
          position={[13.799, 3.613, -6.056]}
          rotation={[-Math.PI, 1.482, -Math.PI]}
          scale={0.986}
        >
          <skinnedMesh
            name="EyeLeft007"
            geometry={nodes.EyeLeft007.geometry}
            material={materials["Wolf3D_Eye.002"]}
            skeleton={nodes.EyeLeft007.skeleton}
            morphTargetDictionary={nodes.EyeLeft007.morphTargetDictionary}
            morphTargetInfluences={nodes.EyeLeft007.morphTargetInfluences}
          />
          <skinnedMesh
            name="EyeRight007"
            geometry={nodes.EyeRight007.geometry}
            material={materials["Wolf3D_Eye.002"]}
            skeleton={nodes.EyeRight007.skeleton}
            morphTargetDictionary={nodes.EyeRight007.morphTargetDictionary}
            morphTargetInfluences={nodes.EyeRight007.morphTargetInfluences}
          />
          <skinnedMesh
            name="Wolf3D_Body007"
            geometry={nodes.Wolf3D_Body007.geometry}
            material={materials["Wolf3D_Body.001"]}
            skeleton={nodes.Wolf3D_Body007.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Hair007"
            geometry={nodes.Wolf3D_Hair007.geometry}
            material={materials["Wolf3D_Hair.002"]}
            skeleton={nodes.Wolf3D_Hair007.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Head007"
            geometry={nodes.Wolf3D_Head007.geometry}
            material={materials["Wolf3D_Skin.002"]}
            skeleton={nodes.Wolf3D_Head007.skeleton}
            morphTargetDictionary={nodes.Wolf3D_Head007.morphTargetDictionary}
            morphTargetInfluences={nodes.Wolf3D_Head007.morphTargetInfluences}
          />
          <skinnedMesh
            name="Wolf3D_Outfit_Bottom007"
            geometry={nodes.Wolf3D_Outfit_Bottom007.geometry}
            material={materials["Wolf3D_Outfit_Bottom.002"]}
            skeleton={nodes.Wolf3D_Outfit_Bottom007.skeleton}
          />
          <skinnedMesh
            name="Wolf3D_Outfit_Footwear007"
            geometry={nodes.Wolf3D_Outfit_Footwear007.geometry}
            material={materials["Wolf3D_Outfit_Footwear.002"]}
            skeleton={nodes.Wolf3D_Outfit_Footwear007.skeleton}
          />
          <group name="Wolf3D_Outfit_Top009">
            <skinnedMesh
              name="Wolf3D_Outfit_Top003_1"
              geometry={nodes.Wolf3D_Outfit_Top003_1.geometry}
              material={materials["Wolf3D_Outfit_Top.003"]}
              skeleton={nodes.Wolf3D_Outfit_Top003_1.skeleton}
            />
            <skinnedMesh
              name="Wolf3D_Outfit_Top003_2"
              geometry={nodes.Wolf3D_Outfit_Top003_2.geometry}
              material={materials["Wolf3D_Outfit_Top.002"]}
              skeleton={nodes.Wolf3D_Outfit_Top003_2.skeleton}
            />
          </group>
          <skinnedMesh
            name="Wolf3D_Teeth007"
            geometry={nodes.Wolf3D_Teeth007.geometry}
            material={materials["Wolf3D_Teeth.002"]}
            skeleton={nodes.Wolf3D_Teeth007.skeleton}
            morphTargetDictionary={nodes.Wolf3D_Teeth007.morphTargetDictionary}
            morphTargetInfluences={nodes.Wolf3D_Teeth007.morphTargetInfluences}
          />
          <primitive object={nodes.Hips_6} />
        </group>
      </group>
    </group>
  );
}

// useGLTF.preload("/office1.glb");
